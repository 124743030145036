<template>
  <div>
    <Buttons>按钮</Buttons>
  </div>
</template>

<script>
import Buttons from "@/components/Buttons";

export default {
  name: "home",
  components: {
    Buttons,
  },
};
</script>
